import Router from 'next/router'
import routeConfig from '../config/route'

export const getRouteConfig = (path, config) => {
    if (config === undefined) config = routeConfig
    let routes = []
    // Collect matches routes from config
    for (let i = 0; i < config.length; i++) {
        let route = config[i]
        if (route.path === path) return route
        // Replace parameter
        let reg = new RegExp(route.path.replace('[*]', '([0-9A-z_-])'))
        if (reg.test(path)) routes.push(route)
    }
    // Return the longest match route
    return routes.sort(
        (a, b) => a.path.split('/').length - b.path.split('/').length
    )[routes.length - 1]
}

export const redirectTo = (destination, { res, status } = {}) => {
    if (res) {
        res.writeHead(status || 302, { Location: destination })
        res.end()
    } else {
        Router.push(destination)
    }
}
