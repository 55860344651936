import roleConfig from '../config/role'
import fetch from '../lib/fetch'

export const roles = roleConfig

export const hasPermission = (permissions, expect) => {
    if (typeof permissions !== 'object') return false
    if (typeof expect === 'string')
        return isOneOfPermission(permissions, expect)
    if (permissions.some((permission) => permission === 'bypass')) return true
    return expect.some((permission) =>
        permissions.some(
            (rolePermission) =>
                rolePermission.toUpperCase() === permission.toUpperCase()
        )
    )
}
export const isOneOfPermission = (permissions, expect) =>
    permissions.some(
        (permission) => permission.toUpperCase() === expect.toUpperCase()
    )
export const rolesAdminGroup = ['Admin']
export const isRole = (role, expect) =>
    role.toUpperCase() === expect.toUpperCase()
export const isOneOfRole = (role, expect) =>
    expect.some((expectRole) => role.toUpperCase() === expectRole.toUpperCase())

export const fetchRoles = async () => {
    const {
        response: { status },
        roles,
    } = await fetch.get(false, {}, '/api/v3/admin/allrole')
    if (status === true) {
        return roles
    }
    return {}
}

export const isAdmin = (role) =>
    typeof role === 'string' ? isRole(role, 'Admin') : false
