import fetch from './fetch'
import Router from 'next/router'
import { parseCookies, destroyCookie } from 'nookies'
import { UpperFirstCase } from '@/lib/string'
import { hasPermission } from './roles'
import { redirectTo } from './route'

export const checkTokenValid = async ({ ctx }) => {
    const cookie = parseCookies(ctx)
    if (cookie.cesto_token_2 !== undefined) {
        const { response, user } = await fetch.get(
            false,
            ctx,
            '/api/v3/admin/check'
        )
        if (response.status === true) {
            ctx.store.dispatch({
                type: 'LOGIN',
                id: user.id,
                fullname: UpperFirstCase(user.fullname),
                name: UpperFirstCase(user.name),
                role: UpperFirstCase(user.role_name),
                roleId: user.role_id,
                permission: user.permission,
                pic: user.profile_picture_url,
                bio: user.bio,
                email: user.email,
            })
            return true
        } else {
            destroyCookie(ctx, 'cesto_token_2')
            return false
        }
    } else {
        return false
    }
}

export const checkAuthen = async ({ ctx, isRouteUnAuthOnly }) => {
    const isTokenValid = await checkTokenValid({ ctx })
    if (isTokenValid && isRouteUnAuthOnly) {
        return '/'
    } else if (!isTokenValid && !isRouteUnAuthOnly) {
        console.log(ctx.asPath)
        if (ctx.asPath === '/') {
            return '/signin'
        } else {
            return '/signin?redirect=' + ctx.asPath
        }
    }
    return false
}

export const checkPermission = ({ permittedPage, userPermissions, ctx }) => {
    if (
        !hasPermission(userPermissions, permittedPage) &&
        !permittedPage.includes('*')
    ) {
        return '/'
    }
    return false
}

export const logout = (dispatch) => {
    destroyCookie({}, 'cesto_token_2')
    dispatch({ type: 'LOGOUT' })
    redirectTo('/signin', {})
}
