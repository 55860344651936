export default [
    { path: '/', permissions: ['*'] },
    { path: '/signin', permissions: ['*'], unAuthOnly: true },

    { path: '/register', permissions: ['*'], unAuthOnly: true },
    { path: '/reset/password', permissions: ['*'], unAuthOnly: true },

    // User Management
    {
        path: '/users',
        permissions: [
            'bypass',
            'all_user_list',
            'estoplus_user_list',
            'esto_user_list'
        ]
    },
    { path: '/profile/edit', permissions: ['*'] },

    // Promotion Management
    {
        path: '/promotion/list',
        permissions: ['bypass', 'all_promotion_list', 'owned_promotion_list']
    },
    {
        path: '/promotion/edit/[*]',
        permissions: ['bypass', 'all_promotion_edit', 'owned_promotion_edit']
    },

    // Info House
    {
        path: '/info/house/list',
        permissions: ['bypass', 'all_house_info_list']
    },
    {
        path: '/info/house/create',
        permissions: ['house_info_create']
    },
    {
        path: '/info/house/[*]',
        permissions: ['bypass', 'all_house_info_read', 'all_house_info_edit']
    },

    // Review house
    {
        path: '/info/house/edit/preview/[*]',
        permissions: [
            'bypass',
            'owned_house_review_view',
            'house_review_view',
            'owned_house_review_edit',
            'house_review_edit'
        ]
    },
    {
        path: '/info/house/edit/review/[*]',
        permissions: [
            'bypass',
            'owned_house_review_view',
            'house_review_view',
            'owned_house_review_edit',
            'house_review_edit'
        ]
    }
]
